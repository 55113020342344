import React from "react"

export default function LayoutInner({ children }) {
  return (
    <div className="bg-white dark:bg-gray-800">
      <div className="max-w-screen-xl xl:mx-auto sm:mx-12 mx-8">
        <div>{children}</div>
      </div>
    </div>
  )
}
