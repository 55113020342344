import React from "react"

export default function ParagraphText(props) {
  return (
    <div className="max-w-screen-md">
      <p className="text-gray-800 dark:text-gray-200 text-medium">
        {props.text}
      </p>
    </div>
  )
}
