import React from "react"
import ParagraphText from "./paragraph_text"
import ParagraphTitle from "./paragraph_title"

export default function Paragraph(props) {
  return (
    <div className="max-w-screen-md">
      <ParagraphTitle text={props.title} />
      <ParagraphText text={props.text} />
    </div>
  )
}
