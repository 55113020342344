import React from "react"
import Navbar from "../components/navbar"
import Footer from "../components/footer"

export default function LayoutOuter({ children }) {
  return (
    <div>
      <Navbar />
      <div className="bg-white dark:bg-gray-800">{children}</div>
      <Footer />
    </div>
  )
}
