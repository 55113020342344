import { Link } from "gatsby"
import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import "../styles/layout.css"

export default function Footer() {
  return (
    <div className="bg-gray-100 dark:bg-black pt-24 pb-24">
      <div className="max-w-screen-xl xl:mx-auto sm:mx-12 mx-8">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h1 className="max-w-max pb-2 text-2xl font-bold bg-gradient-to-r from-tempory_blue_400 to-tempory_logo_pink">
              Tempory
            </h1>

            <p className="text-gray-400 pb-24">Made in Berlin</p>
          </div>
          <div>
            <div className="flex justify-end">
              <ul style={{ textAlign: `end` }}>
                <li className="pr-4">
                  <p className="pb-2 font-semibold text-lg text-gray-800 dark:text-gray-200">
                    Legal
                  </p>
                </li>
                <li className="pr-4">
                  <Link to="/imprint">
                    <p className="text-gray-600 dark:text-gray-400">Imprint</p>
                  </Link>
                </li>
                <li className="pr-4">
                  <Link to="/privacy/website">
                    <p className="text-gray-600 dark:text-gray-400">
                      Privacy Policy
                    </p>
                  </Link>
                </li>
                <li>
                  <OutboundLink href="https://play.google.com/store/apps/details?id=app.tempory">
                    <img
                      className="pt-8"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      width="200"
                      height="auto"
                      alt="Google PlayStore logo"
                    />
                  </OutboundLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-span-2">
            <p className="text-gray-400">André Thiele</p>
            <p className="text-gray-400 text-sm">
              Copyright © <span id="copyyear"></span>
              {new Date().getFullYear()} - All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
