import React from "react"
import { useState } from "react"
import { Link } from "gatsby"

const ListLink = props => (
  <Link to={props.to}>
    <div className="text-gray-800 px-3 py-2 rounded-md font-medium text-lg dark:text-gray-200 hover:bg-tempory_blue_400_alpha_15 hover:text-tempory_blue_400 dark:hover:text-gray-200 transition-colors dark:hover:bg-gray-700">
      {props.children}
    </div>
  </Link>
)

export default function Navbar() {
  const [isOn, setIsOn] = useState(false)

  return (
    <div className="sticky top-0 z-10 bg-white dark:bg-gray-800">
      <nav className="px-0 sm:px-16">
        <div id="nav_mobile">
          <div
            className={`px-6 duration-500 ease-in-out transition sm:hidden ${
              isOn ? "translate-y-50 shadow-xl" : "-translate-y-full"
            } transform pt-16 py-6 w-screen absolute bg-white dark:bg-gray-800 l`}
          >
            <ListLink to="/">Home</ListLink>
            <ListLink to="/blog">Blog</ListLink>
            <ListLink to="/contact">Contact</ListLink>
          </div>
        </div>

        <div className="max-w-screen-xl 2xl:mx-auto sm:px-0 px-8 z-50">
          <div className="relative flex items-center justify-between h-16 dark:bg-gray-800">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                onClick={() => setIsOn(!isOn)}
                className="inline-flex items-center justify-center rounded-md text-gray-800 dark:text-gray-200 focus:outline-none"
                aria-expanded="true"
              >
                <span className="sr-only">Open main menu</span>

                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>

                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="hidden sm:block sm:ml-0 ml-6">
                <div className="flex space-x-4">
                  <ListLink to="/">Home</ListLink>
                  <ListLink to="/blog">Blog</ListLink>
                  <ListLink to="/contact">Contact</ListLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}
