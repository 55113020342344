import React from "react"
import HeaderSub from "../../components/header_sub"
import LayoutOuter from "../../components/layout_outer"
import LayoutInner from "../../components/layout_inner"
import Paragraph from "../../components/paragraph"

export default function PrivacyApp() {
  return (
    <LayoutOuter>
      <LayoutInner>
        <div className="pb-16">
          <div className="py-16">
            <HeaderSub title="Privacy" subtitle="Tempory Mobile App" />
          </div>

          <Paragraph
            title="Types of data collected"
            text="While using the mobile application of Tempory, also called 'App' or 'app' in the following, you may be asked to provide certain personally identifiable information that can be used to contact or identify you ('Personal Data'). Personally identifiable information may include your E-Mail address or information about how the app is accessed and used ('Usage Data'). This includes your Internet Protocol address, phone manufacturer, phone OS version and other diagnostic data needed to investigate problems in the app as well as time and date when using the app."
          />

          <Paragraph
            title="Use of data"
            text="The collected data is used to provide and maintain the app and to participate in features of the app when you choose to do so. Also to provide analysis, to monitor the usage of the app and to detect and address technical issues."
          />

          <Paragraph
            title="Security of data"
            text="The security of your data is important, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure and I cannot guarantee its absolute security. Keep in mind that the app does not transfer any imported information from your phone to external services like information about your contacts and their personal details. The app keeps the provided information on your device by default and only after the user gave the app the permission to do so. The app aims to provide the highest possible security for the users' information at any given time and does so by design."
          />

          <Paragraph
            title="Links to other websites or apps"
            text="The app contains links to other websites or apps that are not operated by me. If you click on a third party link, you will be directed to that third party's website or app. I strongly advise you to review the Privacy Policy of every website or app you use. I have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services."
          />

          <Paragraph
            title="Changes to this Privacy Policy"
            text="This Privacy Policy may get changed from time to time. You will not be notified about any changes. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page."
          />

          <Paragraph
            title="Contact"
            text="If you have any questions about this Privacy Policy, please send an email to: tempory.app@gmail.com"
          />
        </div>
      </LayoutInner>
    </LayoutOuter>
  )
}
