import React from "react"

export default function HeaderSub(props) {
  return (
    <div className="pb-2 mx-auto">
      <h1 className="mx-auto max-w-max py-1.5 text-2xl font-bold bg-gradient-to-r from-tempory_blue_400 to-tempory_logo_pink">
        {props.title}
      </h1>
      <p className="text-center text-4xl font-bold text-gray-800 dark:text-gray-200 text-medium">
        {props.subtitle}
      </p>
    </div>
  )
}
