import React from "react"

export default function ParagraphTitle(props) {
  return (
    <div className="pt-8 max-w-screen-md">
      <p
        className="mb-4 text-2xl font-bold text-gray-800 dark:text-gray-200 text-medium"
        style={{ maxWidth: 500 }}
      >
        {props.text}
      </p>
    </div>
  )
}
